import React from "react";
import { Link } from "react-router-dom";
import { MdSell } from "react-icons/md";

export default function HomePage() {
  return (
    <div
      className="hero max-w-screen-lg mx-auto h-full py-2 md:py-24 -mb-10 bg-transparent"
      style={{
        backgroundImage: "url(" + "/img/bgo.png" + ")",
        backgroundPosition: "bottom",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="hero-content text-center">
        <div className="max-w-full text-center align-middle self-center items-center">
          <p className="text-md text-gray-400 mb-4 font-light tracking-widest uppercase">
          Decentralized ECOMMERCE
          </p>
          <h1 className="text-5xl sm:text-7xl font-bold my-2 min-w-md">
            <b>Sell digital</b>
            <br /> products on Web3
          </h1>
          <p className="py-6 text-lg md:text-xl max-w-md text-center align-middle self-center m-auto text-slate-700">
            Free ≋ Cross-Chain ≋ Private links ≋ Decentralized ≋ Instant payout in ETH,
            BNB or MATIC ≋
          </p>
          <div className="grid items-center grid-cols-1 mx-auto">
            <Link
              to="/new"
              className="btn btn-lg mt-4 max-w-fit mx-auto"
            >
              <MdSell size={22} className="mr-2" /> Start Selling
            </Link>
            <Link
              to="/bafybeidkefhoxp7bq7ija4n3joqmjhbc6r2b3vuvhydwxwg3afhpdss5um"
              className="btn btn-link my-2 mb-10 text-slate-500"
            >
              Demo Product
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
