import React from "react";
import { IPFS } from "const";
import { Watermark } from "@hirohe/react-watermark";

export default function Cover({image}) {
  if (!image) {
    return (
      <img
        src={"/img/placeholder.png"}
        className="max-w-md w-full mx-auto grayscale rounded-xl"
      />
    );
  } else {
    return (
      <Watermark text="COVER" textSize={20}>
        <img
          src={IPFS.cirip + image}
          className="max-w-md w-full mx-auto my-auto justify-center items-center align-middle self-center rounded-xl"
        />
      </Watermark>
    );
  }
}
