import React from "react";
import { observer } from "mobx-react-lite";
import {
  AiOutlineQuestionCircle,
  AiOutlineLogout,
  AiOutlineKey,
} from "react-icons/ai";
import getweb3 from "../../services/getweb3";
import configstore from "../../stores/configstore";
import { truncateString } from "../../utils";
import { Link } from "react-router-dom";
import { IoCube, IoPersonCircleSharp } from "react-icons/io5";
import { getFullChainName } from "../../services/providers";

const ConnectButton = observer(({ route }) => {
  if (!configstore.web3) {
    return (
      <>
        <Link
          to="/new"
          className="btn btn-ghost btn-sm mr-3 text-gray-500"
        >
          Sell now
        </Link>

        <div
          className="btn btn-sm"
          onClick={() => configstore.setShowConnectModal(true)}
        >
          Connect
        </div>
      </>
    );
  } else {
    return (
      <div className="dropdown dropdown-end">
        <label
          tabIndex="0"
          className="btn btn-ghost flex-row w-fit justify-center content-center align-middle"
        >
          <div className="w-5 sm:w-10 rounded-full -ml-2 mask mask-squircle">
            <img src={"https://cdn.stamp.fyi/avatar/" + getweb3.accounts[0]} />
          </div>
          <div className="text-left ml-2">
            <p className="text-xs text-gray-400 font-light capitalize mb-1">
              {getFullChainName(configstore.chainName) ||
                "⚠️ " + configstore.chainName}
            </p>
            <p className="text-md text-gray-600 font-normal">
              {truncateString(getweb3.accounts[0], 13)}
            </p>
          </div>
        </label>
        <ul
          tabIndex="0"
          className="mt-0 p-2 shadow-xl menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
        >
          <li>
            <Link to="/account">
              <IoPersonCircleSharp size={24} className="text-gray-700"/> My Account
            </Link>
          </li>
          <li>
            <Link to="/new">
              <IoCube size={23} className="text-gray-700"/> New Product
            </Link>
          </li>
          <li>
            <a href="https://cirip.tawk.help/" target="_blank">
              <AiOutlineQuestionCircle size={20} className="text-gray-700" /> Help Center
            </a>
          </li>
          <li>
            <a onClick={getweb3.disconnect}>
              <AiOutlineLogout size={20} className="text-gray-700"/> Logout
            </a>
          </li>
        </ul>
      </div>
    );
  }
});

export default ConnectButton;
