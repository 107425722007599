import React from "react";
import { truncateString } from "utils";

export default function Seller({address}) {
  return (
    <div className="avatar placeholder justify-center align-middle">
      <div className="bg-neutral-focus w-8 h-8  mask mask-squircle">
        <img src={"https://cdn.stamp.fyi/avatar/" + address} />
      </div>
      <p className="text-md text-gray-500 my-auto mx-3">
        Seller: {truncateString(address, 15)}
      </p>
    </div>
  );
}
