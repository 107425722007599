import { RPC } from "../const";

export const maticProvider = {
  network: "matic", // here
  rpc: {
    137: RPC.matic,
  },
  chainId: "137",
};

export const bscProvider = {
  network: "binance", // here
  rpc: {
    56: RPC.bsc,
  },
  chainId: "56",
};

export const ethProvider = {
  network: "mainnet", // here
  rpc: {
    1: RPC.eth,
  },
  infuraId: "7407b5f397c54c8388637f31ae2790a0",
  chainId: "1",
};

export const mumbaiProvider = {
  network: "mumbai", // here
  rpc: {
    80001: RPC.mumbai,
  },
  chainId: "80001",
};

export const getFullChainName = (chain) => {
  switch (chain) {
    case "eth":
      return "Ethereum";
    case "bsc":
      return "Smart Chain";
    case "matic":
      return "Polygon";
    case "mumbai":
      return "Mumbai";
    default:
      break;
  }
};

export const getProvider = (chain) => {
  switch (chain) {
    case "eth":
      return ethProvider;
    case "bsc":
      return bscProvider;
    case "matic":
      return maticProvider;
    case "mumbai":
      return mumbaiProvider;
    default:
      break;
  }
};
