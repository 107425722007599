import React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "../pages/Home";
import NewProduct from "../pages/Publish/Product";
// import NewPhoto from "../pages/Publish/Photo";
// import NewArticle from "../pages/Publish/Article";
// import NewAudio from "../pages/Publish/Audio";
// import NewVideo from "../pages/Publish/Video";
// import NewDonation from "../pages/Publish/Donation";
import ItemPage from "../pages/Item";
// import VideoPage from "../pages/Item/Video";
// import { NothingPage } from "../pages/Nothing";
import DelegatePage from "../pages/Delegate";
// import ExternalPage from "../pages/External";
// import MarketplacePage from "../pages/Marketplace";
import CateoriesPage from "../pages/Categories";
import Account from "../pages/Account";

let appRoutes = () => (
  <Routes>
    <Route exact path="/" element={<HomePage />} />
    {/* <Route exact path="/marketplace" element={<MarketplacePage />} /> */}
    <Route exact path="/account" element={<Account />} />
    <Route exact path="/new/product" element={<NewProduct />} />
    {/* <Route exact path="/new/photo" element={<NewPhoto />} />
    <Route exact path="/new/article" element={<NewArticle />} />
    <Route exact path="/new/audio" element={<NewAudio />} />
    <Route exact path="/new/video" element={<NewVideo />} />
    <Route exact path="/new/donation" element={<NewDonation />} /> */}

    <Route exact path="/new" element={<CateoriesPage />} />

    {/* <Route exact path="/external/:token" element={<ExternalPage />} /> */}
    <Route exact path="/delegate" element={<DelegatePage />} />
    <Route exact path="/:id/:nr" element={<ItemPage />} />
    <Route exact path="/:id" element={<ItemPage />} />
    <Route path="*" element={<HomePage/>}/>
  </Routes>
);

export default appRoutes;
