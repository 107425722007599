import Web3 from "web3";
// import Web3Modal from "web3modal";

import Onboard from "@web3-onboard/core";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import walletConnectModule from "@web3-onboard/walletconnect";
import injectedModule from "@web3-onboard/injected-wallets";
import torusModule from "@web3-onboard/torus";
import mewModule from "@web3-onboard/mew";
import gnosisModule from "@web3-onboard/gnosis";
import trezorModule from "@web3-onboard/trezor";
import keepkeyModule from "@web3-onboard/keepkey";
// import web3authModule from "@web3-onboard/web3auth";

// import ledgerModule from "@web3-onboard/ledger";

import configstore from "../stores/configstore";
import CiripService from "../services/cirip";
import { ethProvider, getProvider } from "./providers";
import { getChainNameByID, getProfile } from "./api";
import toast from "react-hot-toast";

const coinbaseWalletSdk = coinbaseWalletModule();
const walletConnect = walletConnectModule();
const injected = injectedModule();
const torus = torusModule();
const mew = mewModule();
const gnosis = gnosisModule();
// const ledger = ledgerModule();
const keepkey = keepkeyModule();
const trezor = trezorModule({
  email: "support@cirip.io",
  appUrl: "https://cirip.io",
  filter: ["mobile"],
});
// const web3auth = web3authModule({
//   clientId:
//     "BG6dtP4rcx7BlL_fn0EdTI0rjjayflWVia0XXkBe9MnKdmwVkrC-wOXZf5jch2_YiPsW950oeW_fDuuqqqNtxBc",
// });

const modules = [
  injected,
  coinbaseWalletSdk,
  walletConnect,
  trezor,
  torus,
  keepkey,
  mew,
  // web3auth,
  gnosis,
];

const chains = [
  {
    id: "0x1", // chain ID must be in hexadecimel
    token: "ETH",
    namespace: "evm",
    label: "Ethereum Mainnet",
    rpcUrl: ethProvider.rpc["1"] + "/v3/7407b5f397c54c8388637f31ae2790a0",
  },
  {
    id: "0x38",
    token: "BNB",
    label: "Binance Smart Chain",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  },
  {
    id: "0x89",
    token: "MATIC",
    label: "Matic Mainnet",
    rpcUrl: "https://matic-mainnet.chainstacklabs.com",
  },
];

class GetWeb3 {
  constructor() {
    this.web3 = null;
    this.accounts = [];
    this.onboard = Onboard({
      wallets: modules, // created in previous step
      chains: chains,
      accountCenter: {
        desktop: {
          position: "bottomRight",
          enabled: false,
          minimal: true,
        },
        mobile: {
          position: "bottomRight",
          enabled: false,
          minimal: true,
        },
      },
      appMetadata: {
        name: "Cirip",
        icon: "https://cirip.io/logo.png",
        logo: "https://cirip.io/logo.png",
        description: "My app using Onboard",
        recommendedInjectedWallets: [
          { name: "Coinbase", url: "https://wallet.coinbase.com/" },
          { name: "MetaMask", url: "https://metamask.io" },
        ],
      },
    });
  }

  connect = async (selectedChain) => {
    try {
      this.wallets = await this.onboard.connectWallet();
      const provider = this.wallets[0].provider;
      this.web3 = new Web3(provider);
      const { numberToHex: toHex } = this.web3.utils;
      let chainid = await this.web3.eth.getChainId();
      let chainName = getChainNameByID(chainid);
      if (chainName !== selectedChain) {
        const changeToChain = getProvider(selectedChain).chainId;
        try {
          const success = await this.onboard.setChain({
            chainId: toHex(changeToChain),
          });
          if (success) {
            chainName = selectedChain;
            chainid = changeToChain;
          }
        } catch (e) {
          console.log(e);
        }
      }
      this.accounts = await this.web3.eth.getAccounts();
      this.web3.eth.defaultAccount = this.accounts[0];
      const msg = "Log in to my account";

      const signature = await this.web3.eth.personal.sign(
        msg,
        this.accounts[0]
      );
      await CiripService.setContract();
      this.web3.eth.transactionBlockTimeout = 500;
      this.web3.eth.transactionPollingTimeout = 2000;
      configstore.setSignature(signature);
      configstore.setWeb3(true);
      configstore.setChain(chainName, chainid);
    } catch (error) {
      console.log(error);
      toast.error("Connection error!");
    }
  };

  disconnect = async () => {
    // this.web3.disconnect()
    const [primaryWallet] = this.onboard.state.get().wallets;
    await this.onboard.disconnectWallet({ label: primaryWallet.label });
    // this.modal.clearCachedProvider();
    this.web3 = null;
    this.providerOptions = null;
    this.accounts = null;
    this.modal = null;
    configstore.setWeb3(false);
  };
}

export default new GetWeb3();
