import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  IoCart,
  IoLogoFacebook,
  IoLogoTwitter,
  IoCube,
  IoShare,
} from "react-icons/io5";
import { observer } from "mobx-react-lite";
import remarkGfm from "remark-gfm";
import { RWebShare } from "react-web-share";
import CurrencyFormat from "react-currency-format";
import Seller from "components/Seller";
import Media from "components/Media";
import { getDownloadLink, getIPFSData, getIPFSHead } from "services/api";
import cirip from "services/cirip";
import configstore from "stores/configstore";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IPFS } from "const";
import account from "stores/account";
import getweb3 from "services/getweb3";
import Description from "components/Description";
import Cover from "components/Cover";

const ItemPage = observer(() => {
  let { id, nr } = useParams();
  const [data, setData] = useState(null);
  const [txInProgress, setTxInProgress] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadURL, setDownloadURL] = useState(null);
  const [canCloseDownloadModal, setCanCloseDownloadModal] = useState(false);
  const [editorState, setEditorState] = useState(null);
  const [showDownload, setShowDownload] = useState(false);
  const [productReady, setProductReady] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(
    "Sometimes IPFS can be slow, you can reload the page or try later."
  );
  const navigate = useNavigate();
  const pCid = nr ? id + "/" + nr : id;

  useEffect(() => {
    checkIpfs();
  }, []);

  useEffect(() => {
    if (configstore.web3) {
      getUrl();
    } else {
      setShowDownload(false);
    }
  }, [configstore.web3, data]);

  const checkIpfs = async () => {
    const isReady = await getIPFSHead(pCid);
    if (isReady === "ready") {
      getData();
    } else if (isReady === "not ready") {
      setProductReady(false);
      setLoadingMessage(
        "🔴 This product is not ready for sale yet. It will take a few minutes to propagate to IPFS."
      );
      toast.error(
        "This product is not ready for sale yet. It will take a few minutes to propagate to IPFS.",
        {
          duration: 10000,
        }
      );
    } else {
      navigate("/");
      toast.error("This product does not exist on IPFS");
      return;
    }
  };

  const getData = async () => {
    try {
      const d = await getIPFSData(pCid);
      if (d.error || d.status === 404 || d.status === 504) {
        navigate("/");
        toast.error("Error trying to fetch the product from IPFS");
        return;
      }
      setData(d);
      setEditorState(d.description);
    } catch (error) {
      console.log(error);
      toast.error("Error trying to fetch the product from IPFS");
      navigate("/");
    }
  };

  const getUrl = async () => {
    const download = await getDownloadLink(pCid, configstore.signature, data);
    if (!download.error) {
      if (download.isBuyer) {
        setShowDownload(true);
        setDownloadURL(IPFS.cirip + download.secret);
        const save = {
          name: download.name,
          price: download.price,
          id: pCid,
          chain:
            configstore.chainName === "matic"
              ? "polygon"
              : configstore.chainName,
        };
        account.addOrder(getweb3.accounts[0], save);
      }
      if (download.isSeller) {
        const save = {
          name: download.name,
          price: download.price,
          id: pCid,
        };
        account.addToStore(getweb3.accounts[0], save);
      }
    }
  };

  const buy = async () => {
    if (configstore.web3) {
      try {
        setTxInProgress(true);
        const r = await cirip.buyCall(pCid, data);
        if (r.transactionHash) {
          // setDownloadModal(true);
          getUrl();
          toast.success("Done! Now you can download the product.", {
            duration: 5000,
          });
        }
        setTxInProgress(false);
      } catch (error) {
        setTxInProgress(false);
        console.log(error);
        toast.error("Insufficient funds");
      }
    } else {
      //TODO remove loop
      toast.error("You need to connect first!");
    }
  };

  const renderBuyBtn = () => {
    if (showDownload) {
      return (
        <button
          className="btn btn-success btn-md lg:btn-lg w-full  text-white"
          onClick={() => {
            // const downloadLink = `${downloadURL}?filename=${data.filename}&download=true`;
            // window.location.href = downloadURL;
            window.open(
              downloadURL,
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
        >
          Download
        </button>
      );
    }
    return (
      <>
        {/* <div className="stat-title mb-2 text-sm">Pay with Crypto</div> */}
        <button
          className="btn btn-md w-full btn-warning lg:btn-lg "
          onClick={buy}
          disabled={!productReady}
        >
          <IoCart size={20} className="mr-2" />
          Buy Now
        </button>
      </>
    );
  };

  const downloadNow = async () => {
    try {
      const download = await getDownloadLink(pCid, configstore.signature, data);
      if (download) {
        if (download.error) {
          toast.error(download.error);
        } else {
          // setDownloadURL(`https://${download.secret}.ipfs.w3s.link`);
          // setShowDownload(true);
          // window.location.href = `https://${download.secret}.ipfs.w3s.link`;
          // setCanCloseDownloadModal(true);
          // setDownloadModal(false)
        }
        setDownloadModal(false);
        toast.success("Done! Now you can download the product.", {
          duration: 5000,
        });
      } else {
        toast.error("Invalid request!");
      }
    } catch (error) {
      toast.error("Invalid request!");
    }
  };

  if (!data) {
    return (
      <div
        className="mx-auto justify-center align-middle text-center"
        style={{ minHeight: "500px" }}
      >
        <lottie-player
          className="justify-center self-center"
          src="https://assets2.lottiefiles.com/packages/lf20_7uxmdgoe.json"
          background="transparent"
          speed="1"
          center
          style={{ width: "300px", height: "300px" }}
          loop
          autoplay
        ></lottie-player>
        <div className="text-2xl mb-2">Fetching the product from IPFS...</div>
        <div className="text-gray-500 max-w-sm">{loadingMessage}</div>
        <button
          className="btn btn-ghost btn-active my-6"
          onClick={() => window.location.reload()}
        >
          Reload the page
        </button>
      </div>
    );
  } else {
    return (
      <div className="grid lg:grid-cols-10 gap-2 max-w-screen-xl mx-auto mt-4">
        <div className="max-w-full col-span-3 lg:col-span-4  mx-auto  h-fit ">
          <div
            className="p-3 lg:p-5 rounded-xl bg-white shadow-sm justify-center flex place"
            style={{
              backgroundImage: "url(" + "/img/placeholder.png" + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Cover image={data.image}/>
          </div>
          <div className="flex flex-row justify-between my-3 px-3 py-4 lg:p-5 bg-white  rounded-xl shadow-sm">
            <div className="flex-1">{renderBuyBtn()}</div>
            <div className="divider divider-horizontal"></div>
            <div className="flex flex-row flex-1 justify-center ">
              <div className="flex align-middle content-center items-center">
                <div className="flex align-middle content-center items-center text-2xl lg:text-4xl font-bold text-gray-800">
                  <CurrencyFormat
                    value={data.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between my-3 px-3 bg-zinc-50  rounded-xl text-center hide-mobile shadow-sm">
            <div className="flex-1">
              <div class="stat">
                <div class="stat-title">Digital Product</div>
                {/* <div class="stat-value">Product</div> */}
                <div class="stat-value">
                  <img
                    src="/img/cube.png"
                    className="w-10 h-10 my-1 mx-auto grayscale hover:grayscale-0"
                  />
                </div>
              </div>
            </div>
            <div className="divider divider-horizontal"></div>
            <div className="flex flex-row flex-1 justify-center">
              <div class="stat">
                <div className="stat-title">File</div>
                <div class="stat-value text-gray-500">
                  {data.filename
                    .substring(data.filename.lastIndexOf(".") + 1)
                    .toUpperCase()}
                </div>
                <div class="stat-desc">Stored on IPFS</div>
              </div>
            </div>
          </div>
          {/* <p className="text-sm text-gray-400 text-center max-w-sm mx-auto my-6">
            Buying a product means you agree to our Terms & Conditions
          </p> */}

          {/* <div className="divider"></div> */}
          <div className="justify-center flex my-6">
            <div className="flex align-middle content-center items-center ">
              <a className="btn btn-circle btn-sm bg-gray-500 border-gray-500 mr-3 opacity-50 hover:opacity-100">
                <RWebShare
                  data={{
                    text: data.name,
                    url: window.location.href,
                    title: "Cirip Share",
                  }}
                  onClick={() => console.info("share successful!")}
                >
                  <IoShare size={20} className="text-white" />
                </RWebShare>
              </a>

              <a
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=" +
                  window.location.href
                }
                target="_blank"
                className="btn btn-circle btn-sm bg-gray-500 border-gray-500 mr-3 opacity-50 hover:opacity-100"
              >
                <IoLogoFacebook size={20} className="text-white" />
              </a>
              <a
                href={
                  "https://twitter.com/intent/tweet?text=Check out this item on&url=" +
                  window.location.href
                }
                target="_blank"
                className="btn btn-circle btn-sm bg-gray-500 border-gray-500 opacity-50 hover:opacity-100"
              >
                <IoLogoTwitter size={18} className="text-white" />
              </a>
            </div>
            <div className="divider divider-horizontal"></div>
            <div className="flex align-middle content-center items-center">
              <img
                src="/img/eth.svg"
                width="30px"
                className="mr-3 grayscale opacity-50"
              />
              <img
                src="/img/bsc.svg"
                width="30px"
                className="mr-3 grayscale opacity-50"
              />
              <img
                src="/img/polygon.svg"
                width="30px"
                className="grayscale opacity-50"
              />
            </div>
          </div>
        </div>
        <div className="max-w-full col-span-3 lg:col-span-6  rounded-xl p-3 lg:p-6 bg-white shadow-sm">
          <div className="justify-evenly h-full flex-col flex">
            <div className="flex-1">
              <div className="flex justify-between">
                <Seller address={data.seller} />
                <div className="justify-end">
                  <a className="btn btn-circle btn-sm bg-gray-200 border-gray-200 hover:bg-gray-100 hover:border-gray-100 mr-3 hide-mobile">
                    <RWebShare
                      data={{
                        text: data.name,
                        url: window.location.href,
                        title: "Cirip Share",
                      }}
                      onClick={() => console.info("share successful!")}
                    >
                      <IoShare size={20} className="text-gray-500" />
                    </RWebShare>
                  </a>
                  <a
                    href={IPFS.cirip + pCid}
                    target="_blank"
                    className="btn btn-circle btn-sm bg-gray-200 border-gray-200 hover:bg-gray-100 hover:border-gray-100"
                  >
                    <IoCube size={20} className="text-gray-500" />
                  </a>
                </div>
              </div>
              <h1 className="text-4xl font-black mt-6 mb-6">{data.name}</h1>
              {/* <Media title="Video" source="ceva"/> */}
              <Description editorState={editorState} remarkGfm={remarkGfm}/>
            </div>
          </div>
        </div>
        {/* <div className="col-span-3 lg:col-span-4 h-fit mt-4 "></div>
        <div className="col-span-3 lg:col-span-6 mt-4 "></div> */}

        <input
          type="checkbox"
          id="my-modal-6"
          className="modal-toggle"
          checked={txInProgress}
        />
        <div className="modal modal-middle sm:modal-middle">
          <div className="modal-box">
            <label
              onClick={() => setTxInProgress(false)}
              for="my-modal-3"
              class="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
            <h3 className="font-bold text-xl">
              Confirm payment in your wallet
            </h3>
            <p className="py-4">
              Download link available on payment validation: ~15s+.
            </p>
            <p className="py-2 mb-4">
              Blockchains can be slow at times. You may return to this product
              page and connect your wallet again to download.
            </p>
            <button className="btn loading">Transaction in progress</button>
          </div>
        </div>
        <input
          type="checkbox"
          id="my-modal-6"
          className="modal-toggle"
          checked={downloadModal}
        />
        <div className="modal modal-middle sm:modal-middle">
          <div className="modal-box">
            <label
              onClick={() => setDownloadModal(false)}
              for="my-modal-3"
              class="btn btn-sm btn-circle absolute right-2 top-2"
            >
              ✕
            </label>
            <h3 className="font-bold text-xl">Done</h3>
            <p className="py-4">
              Download now, in the future you could not be able to download this
              product anymore.
            </p>
            <button className="btn btn-success" onClick={downloadNow}>
              Download
            </button>
            {canCloseDownloadModal ? (
              <>
                <p className="pt-4 text-sm text-gray-500">
                  The link to the file download is:
                </p>
                <div className="flex">
                  <a
                    className="text-sm link text-ellipsis truncate"
                    href={downloadURL}
                    target="_blank"
                  >
                    {downloadURL}
                  </a>
                </div>
                <div
                  className="modal-action"
                  onClick={() => setDownloadModal(false)}
                >
                  <label for="my-modal-6" className="btn">
                    Close
                  </label>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
});
export default React.memo(ItemPage);
