export const ENDPOINTS = {
  server: "https://api.cirip.io",
  // server: "https://cirip2.herokuapp.com",
  // server: "http://localhost:3002",
};

export const IPFS = {
  cirip: "https://gateway.ipfscdn.io/ipfs/",
  dweb: "ipfs.dweb.link/",
  w3s: "ipfs.w3s.link/",
  cf: "https://cloudflare-ipfs.com/ipfs/",
};

export const RPC = {
  eth: "https://mainnet.infura.io",
  matic: "https://polygon-rpc.com",
  bsc: "https://bscrpc.com",
  mumbai: "https://rpc-mumbai.maticvigil.com",
};

export const ChainExporer = {
  eth: "https://etherscan.io/address/",
  matic: "https://polygonscan.com/address/",
  bsc: "https://bscscan.com/address/",
  mumbai: "https://mumbai.polygonscan.com/address/",
};
