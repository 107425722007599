import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { publishProduct } from "../../../services/api";
import { observer } from "mobx-react-lite";
import configstore from "../../../stores/configstore";
import getweb3 from "../../../services/getweb3";
import toast from "react-hot-toast";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { DropzoneFile } from "../../../components/Dropzone";

// https://www.markdownguide.org/basic-syntax/

const NewProduct = observer(() => {
  const [title, setTitle] = useState(null);
  const [price, setPrice] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [markdownPreview, setMarkdownPreview] = useState(false);
  const [editorState, setEditorState] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': [],
      'video/*': [],
      'application/zip': [],
      'audio/*': []

    }
  });
  let navigate = useNavigate();

  const files = acceptedFiles.map((file) => file.name);

  const renderPublish = () => {
    if (!inProgress) {
      return (
        <button className="btn btn-md text-white min-w-full" onClick={publish}>
          Publish
        </button>
      );
    } else {
      return (
        <button
          className="btn btn-md text-white min-w-full loading"
          onClick={publish}
        >
          Publishing...
        </button>
      );
    }
  };

  const publish = async () => {
    if (!configstore.web3) {
      // connect
      toast.error("You need to connect first");
      return;
    }
    if (editorState) {
      if (editorState.length > 40000) {
        toast.error(
          "Description is too large " + editorState.length + "/40000"
        );
        return;
      }
    }
    if (!title || title.length === 0) {
      toast.error("Enter the product title");
      return;
    }
    if (!price || price.length === 0 || parseFloat(price) <= 0) {
      toast.error("Enter the product price");
      return;
    }
    if (!acceptedFiles[0] || acceptedFiles.length > 1) {
      toast.error("Select the file(only one) you want to sell");
      return;
    }
    if (acceptedFiles[0].size > 1073741824) {
      toast.error("File you sale should be max 1GB");
      return;
    }
    if (!previewFile) {
      toast.error("Select cover image!");
      return;
    }
    if (previewFile.size > 500000) {
      toast.error("Cover Image should be max 500KB");
      return;
    }

    const data = {
      name: title,
      description: editorState,
      image: previewFile,
      seller: getweb3.accounts[0],
      price: price,
      secret: acceptedFiles[0],
      type: "file",
      signature: configstore.signature,
    };
    setInProgress(true);
    const p = await publishProduct(data);
    if (p.cid) {
      navigate("/" + p.cid);
    }
    setInProgress(false);
  };

  const renderMarkdownPreview = () => {
    if (markdownPreview) {
      return (
        <>
          <div className="border-2 p-2 rounded-xl mb-2">
            <ReactMarkdown
              children={editorState}
              remarkPlugins={[remarkGfm]}
              className={"min-h-6"}
            />
          </div>
          <a
            className="link text-sm link-hover"
            href="https://www.markdownguide.org/basic-syntax/"
            target="_blank"
          >
            Markdown Guide
          </a>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="grid lg:grid-cols-4 gap-12 mt-4 max-w-screen-lg mx-auto mb-auto">
      <div className="col-span-3">
        <div className="p-4 px-5 bg-white rounded-xl shadow-md">
          <h1 className="text-2xl font-bold mb-5 inline-flex my-auto">
            <img src="/img/cube.png" className="w-6 mr-3 my-auto" />
            New product
          </h1>
          <input
            type="text"
            placeholder="Product title"
            className="input w-full text-lg input-lg placeholder-gray-500"
            onChange={(e) => setTitle(e.target.value)}
          />
          <DropzoneFile getFile={setPreviewFile} />
          <div className="mt-5 markdown break-words">
            <textarea
              class="textarea w-full h-44 placeholder-gray-500 text-md mb-2"
              placeholder="Description (plain text or markdown)"
              onChange={(e) => setEditorState(e.target.value)}
              value={editorState}
            ></textarea>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text text-gray-600">
                  Markdown Preview:
                </span>
                <input
                  type="checkbox"
                  className="toggle"
                  checked={markdownPreview}
                  onChange={() => setMarkdownPreview(!markdownPreview)}
                />
              </label>
            </div>
            {renderMarkdownPreview()}
          </div>
          {configstore.web3 ? (
            <p className="text-sm mt-4 font-medium p-1 bg-slate-50 rounded-lg break-all text-gray-700">
              ＄ Instant Payout to: {getweb3.accounts[0] || "Connect first"}
            </p>
          ) : null}
        </div>
        <div className="my-2">
          <div
            tabindex="0"
            className="collapse collapse-arrow bg-base-100 rounded-box"
          >
            <input type="checkbox" class="peer" />
            <div className="collapse-title text-gray-500 bg-white text-lg">
              How it works
            </div>
            <div className="collapse-content bg-white">
              <p className="text-sm text-gray-400">
                🏷 The product will be available for purchase on Ethereum,
                Binance Smart Chain and Polygon Blockchains. You will receive
                the direct payment in ETH, BNB or MATIC on your address.
              </p>
              <p className="text-sm mt-2 text-gray-400">
                💱 USD prices are converted to ETH, BNB or MATIC at the time of
                purchase based on market price.
              </p>
              <p className="text-sm mt-2 text-gray-400">
                🔗 The generated link of the product is private. Only the people
                who know the link will be able to see and buy the product.
              </p>
              <p className="text-sm text-gray-500 mt-3">
                For more information visit Help Center
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-0 mx-auto col-span-3 lg:col-auto">
        <div className="stats stats-vertical  mx-auto text-center items-center bg-white block shadow-md">
          <div className="stat">
            <div className="stat-title mb-2">Set Price</div>
            <div className="stat-value">
              <input
                type="number"
                min="0"
                placeholder="$0"
                className="input w-full text-lg text-center font-bold input-bordered input-success"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
          <div className="stat">
            <div className="stat-title text-sm mb-2">File for sale</div>
            <div>
              <div
                {...getRootProps({ className: "dropzone" })}
                className="btn btn-success btn-md  min-w-full" 
              >
                <input {...getInputProps()} multiple={false} />
                <p className="truncate w-32">
                  {files.length > 0 ? files : "Select File "}
                </p>
              </div>
            </div>
            <div className="stat-desc mt-1 text-gray-500">image, video, audio or zip</div>
          </div>
          <div className="stat bg-base-200">
            <div className="stat-title mb-1">Fee/Sale</div>
            <div className="stat-value text-zinc-700">3%</div>
          </div>
          <div className="stat bg-base-200">
            <div className="card-actions justify-center">{renderPublish()}</div>
          </div>
        </div>
        <p className="text-xs mt-4 text-gray-400 text-center px-4">
          Selling a product means you agree to our Terms & Conditions
        </p>
      </div>
    </div>
  );
});
export default React.memo(NewProduct);
