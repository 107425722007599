import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { observer } from "mobx-react-lite";
import { IoCopy, IoRefresh } from "react-icons/io5";
import { regenerateToken } from "../../services/api";
import configstore from "../../stores/configstore";
import toast from "react-hot-toast";

const DelegatePage = observer(() => {
  const [url, setUrl] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (!configstore.web3 || !configstore.signature) {
      navigate("/");
      return;
    }
    setUrl("https://cirip.io/external/" + configstore.delegateToken);
  }, [configstore.delegateToken]);

  const regenerate = async () => {
    if (!configstore.web3 || !configstore.signature) {
      toast.error("You need to connect first!");
      return;
    }
    const { delegateToken } = await regenerateToken(configstore.signature);
    configstore.setDelegateToken(delegateToken);
  };
  const copyUrl = () => {
    copy(url, {
      debug: false,
      message: "Press #{key} to copy",
    });
  };
  return (
    <div className="grid lg:grid-cols-4 gap-12 mt-3 max-w-screen-lg mx-auto mb-auto">
      <div className="col-span-3">
        <div className="p-4 px-5 bg-white rounded-xl ">
          <h1 className="text-2xl font-black mb-4">
            Delegate publishing (No Auth)
          </h1>
          <p className="mb-3 text-gray-500">
            Have others publish using your public wallet address without sharing
            your wallet keys.
          </p>
          <p className="mb-8 text-gray-500">
            Using this dedicated URL you or people you decide to share the URL
            with, can publish products for sale without connecting a wallet.
          </p>
          <input
            type="text"
            disabled
            value={url}
            className="input w-full text-lg input-lg placeholder-gray-500"
          />

          <p className="mb-1 mt-8 text-gray-500">
            Payments will be made towards your wallet.
          </p>
        </div>
      </div>
      <div className="mt-0 mx-auto col-span-3 lg:col-auto w-full">
        <div className="stats stats-vertical  mx-auto text-center items-center bg-white block">
          <div className="stat">
            <div className="card-actions justify-center">
              <button className="btn btn-md  min-w-full" onClick={copyUrl}>
                <IoCopy size={17} className="mr-2" />
                Copy URL
              </button>
            </div>
          </div>
          <div className="stat">
            <div className="card-actions justify-center">
              <button
                className="btn btn-md min-w-full btn-success"
                onClick={regenerate}
              >
                <IoRefresh size={17} className="mr-1" />
                Regenerate
              </button>
            </div>
          </div>
        </div>
        <p className="text-xs mt-4 text-gray-400 text-center px-4">
        Generate a new URL to disable the old one
        </p>
      </div>
    </div>
  );
});
export default React.memo(DelegatePage);
