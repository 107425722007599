import { ENDPOINTS, IPFS } from "../const";
import configstore from "../stores/configstore";
import toast from "react-hot-toast";
const axios = require("axios").default;

export const publishProduct = async (data) => {
  const formdata = new FormData();
  if (data.product) {
    formdata.append("product", data.product, data.product.name);
  }
  formdata.append("name", data.name);
  formdata.append("description", data.description);
  formdata.append("seller", data.seller);
  formdata.append("price", data.price);
  formdata.append("signature", data.signature);
  formdata.append("type", data.type);
  if (data.secret) {
    formdata.append("secret", data.secret);
  }
  if (data.image) {
    formdata.append("image", data.image);
  }

  const requestOptions = {
    method: "POST",
    body: formdata,
  };

  const f = await fetch(ENDPOINTS.server + "/new", requestOptions);
  return await f.json();
};

export const getID = async (id) => {
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const f = await fetch(ENDPOINTS.server + "/id/" + id, requestOptions);
  return await f.json();
};

export const getIPFSData = async (cid) => {
  const instance = axios.create({
    baseURL: IPFS.cirip + cid,
    timeout: 10000,
    method: "GET",
  });

  try {
    let response = await instance();
    return response.data;
  } catch (error) {
    console.log(error)
    toast.error("Cannot get the product data. Try again!");
    // instance.baseURL = "https://" + cid + "." + IPFS.dweb;
    // const response = await instance();
    // console.log("----2", response);
    // return response.data;
    console.log(error)
    // try {
    //   const f = await fetch("https://" + cid + "." + IPFS.dweb, requestOptions);
    //   return await f.json();
    // } catch (error) {
    //   try {
    //     const f = await fetch(
    //       "https://" + cid + "." + IPFS.w3s,
    //       requestOptions
    //     );
    //     return await f.json();
    //   } catch (error) {
    //     try {
    //       const f = await fetch(IPFS.cf + cid, requestOptions);
    //       return await f.json();
    //     } catch (error) {
    //       toast.error("Cannot get the product data. Try again!");
    //     }
    //   }
    // }
  }
};

export const getIPFSHead = async (cid) => {
  const instance = axios.create({
    baseURL: IPFS.cirip + cid,
    timeout: 4000,
    method: "head",
  });
  let status = true;
  try {
    await instance()
      .then(function (response) {
        if (response.status === 200) {
          status = 'ready';
        } else {
          status = 'not ready';
        }
      })
      .catch(function (error) {
        status = 'not exists';
        if(error.code === "ECONNABORTED"){
          status = 'not ready'
        }
        return status;
      });
      return status;
  } catch (error) {
    status = 'not exists';
    return status;
  }
};

export const getDownloadLink = async (id, signature, data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    cid: id,
    signature: signature,
    chain: configstore.chainName,
    data: JSON.stringify(data),
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  const f = await fetch(ENDPOINTS.server + "/product", requestOptions);
  return await f.json();
};

export const getPrices = async () => {
  var requestOptions = {
    method: "GET",
  };

  const prices = await fetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=ethereum,binancecoin,matic-network&vs_currencies=usd",
    requestOptions
  );
  const p = await prices.json();
  const formatPrices = {
    1: p.ethereum.usd,
    137: p["matic-network"].usd,
    56: p.binancecoin.usd,
    80001: p["matic-network"].usd,
  };
  return formatPrices[configstore.chainID];
};

export const getTxData = async (id, signature, data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    cid: id,
    signature: signature,
    chain: configstore.chainName,
    data: data,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  const f = await fetch(ENDPOINTS.server + "/tx", requestOptions);
  return await f.json();
};

export const deleteProduct = async (id, signature) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    id: id,
    signature: signature,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  const f = await fetch(ENDPOINTS.server + "/delete", requestOptions);
  return await f.json();
};

export const getProfile = async (signature) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    signature: signature,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  const f = await fetch(ENDPOINTS.server + "/profile", requestOptions);
  return await f.json();
};

export const regenerateToken = async (signature) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    signature: signature,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  const f = await fetch(ENDPOINTS.server + "/delegate", requestOptions);
  return await f.json();
};

export const getDelegate = async (token) => {
  var myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const f = await fetch(
    ENDPOINTS.server + "/delegate/" + token,
    requestOptions
  );
  return await f.json();
};

export const publishExternalProduct = async (data) => {
  const formdata = new FormData();
  formdata.append("product", data.product, data.product.name);
  formdata.append("name", data.name);
  formdata.append("description", data.description);
  formdata.append("seller", data.seller);
  formdata.append("price", data.price);
  formdata.append("token", data.token);
  formdata.append("gumroad", data.gumroad);

  const requestOptions = {
    method: "POST",
    body: formdata,
  };

  const f = await fetch(ENDPOINTS.server + "/eproduct", requestOptions);
  return await f.json();
};

export const getChainNameByID = (chainID) => {
  switch (chainID) {
    case 1:
      return "eth";
    case 137:
      return "matic";
    case 56:
      return "bsc";
    case 80001:
      return "mumbai";
    default:
      return null;
  }
};
