import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import configstore from "../../stores/configstore";
import { useNavigate, Link } from "react-router-dom";
import account from "../../stores/account";
import getweb3 from "../../services/getweb3";
import CurrencyFormat from "react-currency-format";

const AccountPage = observer(() => {
  const [orderList, setOrderList] = useState([]);
  const [productList, setProductList] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (configstore.web3 && account.myOrders[getweb3.accounts[0]]) {
      const orders = account.myOrders[getweb3.accounts[0]];
      if (orders.length > 0) {
        const orderItems = account.myOrders[getweb3.accounts[0]].map(
          (order) => {
            return (
              <tr className="h-16">
                <td>
                  {order.name.length > 30 ? order.name.substring(0,30) + '...' : order.name}
                </td>
                <td>
                  <CurrencyFormat
                    value={order.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </td>
                <td>
                  <Link to={"/" + order.id} className="btn btn-xs btn-outline">
                    Open
                  </Link>
                </td>
                <td>
                  <img
                    src={`/img/${order.chain.toLocaleLowerCase()}.svg`}
                    alt="chain"
                    width={25}
                  />
                </td>
              </tr>
            );
          }
        );
        setOrderList(orderItems);
      }
    }
  }, [configstore.web3]);

  useEffect(() => {
    if (configstore.web3 && account.myStore[getweb3.accounts[0]]) {
      const products = account.myStore[getweb3.accounts[0]];
      if (products.length > 0) {
        const productItems = account.myStore[getweb3.accounts[0]].map(
          (product) => {
            return (
              <tr className="h-16">
                <td>
                  {product.name.length > 30 ? product.name.substring(0,30) + '...' : product.name}
                </td>
                <td>
                  <CurrencyFormat
                    value={product.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                </td>
                <td>
                  <Link
                    to={"/" + product.id}
                    className="btn btn-xs btn-outline"
                  >
                    Open
                  </Link>
                </td>
                <td>
                  <div className="avatar-group -space-x-4 w-full">
                    <div className="avatar">
                      <div className="w-6">
                        <img src="/img/eth.svg" />
                      </div>
                    </div>
                    <div className="avatar">
                      <div className="w-6">
                        <img src="/img/polygon.svg" />
                      </div>
                    </div>
                    <div className="avatar">
                      <div className="w-6">
                        <img src="/img/bsc.svg" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            );
          }
        );
        setProductList(productItems);
      }
    }
  }, [configstore.web3]);

  return (
    <div className="grid lg:grid-cols-2 gap-2 w-full max-w-screen-xl mb-auto mx-auto mt-4">
      <div className="max-w-full col-span-3 lg:col-span-1  rounded-xl p-3 lg:p-6 bg-white mx-2 h-fit shadow-md">
        <div className="justify-evenly h-full flex-col flex">
          <div className="flex-1">
            <h1 className="text-2xl font-bold mb-2">My Orders</h1>
            <p className="text-md mb-6 text-gray-400">
              List of your purchases from this browser.
            </p>
            {orderList.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Link</th>
                      <th>Network</th>
                    </tr>
                  </thead>
                  <tbody>{orderList}</tbody>
                </table>
              </div>
            ) : (
              <div className="justify-center text-center my-12">
                <p className="text-gray-300 font-black text-lg">No Orders</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="max-w-full col-span-3 lg:col-span-1  rounded-xl p-3 lg:p-6 bg-white mx-2 h-fit shadow-md">
        <div className="justify-evenly h-full flex-col flex">
          <div className="flex-1">
            <h1 className="text-2xl font-bold mb-2">My Products</h1>
            <p className="text-md mb-6 text-gray-400">
              List of your created products from this browser.
            </p>
            {productList.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Link</th>
                      <th>Network</th>
                    </tr>
                  </thead>
                  <tbody>{productList}</tbody>
                </table>
              </div>
            ) : (
              <div className="justify-center text-center my-12">
                <p className="text-gray-300 font-black text-lg">No Products</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
export default React.memo(AccountPage);
