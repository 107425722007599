import React from 'react'
import ReactDOM from 'react-dom'
import './styles/output.css';
// import './index.css';
// import './styles/ss.scss';
import { BrowserRouter } from 'react-router-dom'
import getweb3 from "./services/getweb3";
import App from './App'

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)