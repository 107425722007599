import { makeAutoObservable, action } from "mobx";
import { makePersistable } from "mobx-persist-store";

class AccountStore {
  myOrders = {};
  myStore = {};

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "CiripAccountStore",
      properties: ["myOrders", "myStore"],
      storage: window.localStorage,
      stringify: true,
    });
  }

  addOrder = action((address, product) => {
    if (this.myOrders[address]) {
      const found = this.myOrders[address].some((el) => el.id === product.id);
      if (!found) this.myOrders[address].push(product);
    } else {
      this.myOrders[address] = [];
      this.myOrders[address].push(product);
    }
  });

  addToStore = action((address, product) => {
    if (this.myStore[address]) {
      const found = this.myStore[address].some((el) => el.id === product.id);
      if (!found) this.myStore[address].push(product);
    } else {
      this.myStore[address] = [];
      this.myStore[address].push(product);
    }
  });
}
export default new AccountStore();
