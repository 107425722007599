import React from "react";
import Routes from "./routes";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import ConnectButton from "./components/Menu/Connect";
import configstore from "./stores/configstore";
import { observer } from "mobx-react-lite";
import getweb3 from "./services/getweb3";

const App = observer(() => {
  const location = useLocation();

  const connectTo = async (chain) => {
    configstore.setShowConnectModal(false);
    await getweb3.connect(chain);
  };

  return (
    <div className="flex flex-col h-screen justify-between mx-auto">
      <div className="navbar mt-2 max-w-screen-xl mx-auto">
        <div className="flex-1">
          <Link to="/">
            <img src="/logo.png" className="w-10" />
          </Link>
          <Link
            to="/"
            className="text-2xl ml-2 font-semibold text-stone-800 logo"
          >
            Cirip
          </Link>
        </div>
        {!location.pathname.includes("external") ? (
          <div className="navbar-end h-10 flex-none">
            <ConnectButton />
          </div>
        ) : null}
      </div>
      <Routes />
      <div className="bg-base-200 w-full mt-10">
        <footer className="footer py-10 px-5 text-base-content mx-auto max-w-screen-xl justify-between">
          <div className="">
            <img src="/logo.png" className="w-12 mb-1 grayscale" />
            <p>
              &copy; 2022 Cirip.io
              <br />
              <span className="text-gray-500 text-xs">
                A decentralized service, free and cross-chain.
              </span>
              <p className="text-gray-500 text-xs">
                The products files and metadata are stored on IPFS.
              </p>
            </p>
          </div>
          <div>
            <span className="footer-title">Navigation</span>
            <a
              className="link link-hover"
              href="https://vote.cirip.io"
              target="_blank"
            >
              Governance
            </a>
            <a
              className="link link-hover"
              href="https://cirip.tawk.help/"
              target="_blank"
            >
              Help Center
            </a>
            <a
              className="link link-hover"
              href="https://docs.cirip.io"
              target="_blank"
            >
              Docs
            </a>
          </div>
          <div>
            <span className="footer-title">Legal</span>
            <a
              className="link link-hover"
              href="https://docs.cirip.io/legal/terms-and-conditions"
              target="_blank"
            >
              Terms & Conditions
            </a>
            <a
              className="link link-hover"
              href="https://docs.cirip.io/legal/privacy-policy"
              target="_blank"
            >
              Privacy policy
            </a>
            <a
              className="link link-hover"
              href="https://docs.cirip.io/help/contact-us"
            >
              Contact
            </a>
          </div>
          <div className="justify-items-start md:justify-items-end">
            <span className="footer-title">Powered by:</span>
            <a
              className="link opacity-70"
              href="https://thirdweb.com/"
              target="_blank"
            >
              <img src="/img/thirdweb_logo.png" width="120px" />
            </a>
            <img src="/img/ipfs_logo.png" width="150px" className="opacity-70" />
          </div>
        </footer>
      </div>
      <input
        type="checkbox"
        id="my-modal-3"
        className="modal-toggle"
        checked={configstore.showConnectModal}
      />
      <div class="modal">
        <div class="modal-box relative">
          <label
            onClick={() => configstore.setShowConnectModal(false)}
            for="my-modal-3"
            class="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 class="text-xl font-bold">Choose Network</h3>
          <div className="mt-4 grid grid-cols-3 gap-2 w-full py-4">
            <button
              class="btn self-center btn-link mb-2 min-h-full text-black bg-transparent border-0 text-sm"
              onClick={() => connectTo("eth")}
            >
              <img src="/img/eth.svg" alt="eth" width={60} className="mb-2" />{" "}
              Ethereum
            </button>
            <button
              class="btn self-center btn-link mb-2 min-h-full text-black bg-transparent border-0 text-sm"
              onClick={() => connectTo("bsc")}
            >
              <img src="/img/bsc.svg" alt="eth" width={60} className="mb-2" />
              Binance Smart Chain
            </button>
            <button
              class="btn self-center btn-link mb-2 min-h-full text-black bg-transparent border-0 text-sm"
              onClick={() => connectTo("matic")}
            >
              <img
                src="/img/polygon.svg"
                alt="eth"
                width={60}
                className="mb-2"
              />
              Polygon
            </button>
          </div>
          <div class="divider mt-12"></div>
          {/* <div className="mt-4">
            <button
              class="btn self-center w-full mb-2 btn-active btn-ghost text-sm"
              onClick={() => connectTo("mumbai")}
            >
              Polygon testnet (mumbai)
            </button>
          </div> */}
          <p className="text-sm mt-2 px-2 text-center text-gray-400">
            The products are blockchain independent. A published product can be
            purchase from any chain.
          </p>
        </div>
      </div>
      <Toaster />
    </div>
  );
});

export default React.memo(App);
