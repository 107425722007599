import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

export const DropzoneFile = ({ getFile }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
    }
  });
  const files = acceptedFiles.map((file) => file.name);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      if (acceptedFiles[0].size > 500000) {
        toast.error("Preview Image should be max 500KB");
        return;
      }
      getFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <div className="mt-4">
      <div
        {...getRootProps({ className: "dropzone" })}
        className="btn  btn-ghost btn-md  min-w-full bg-gray-100"
      >
        <input {...getInputProps()} multiple={false} />
        <p className="">{files.length > 0 ? files : "Cover Image"}</p>
      </div>
    </div>
  );
};
