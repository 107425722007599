import React from "react";
import { Link } from "react-router-dom";

export default function CateoriesPage() {
  return (
    <div className="max-w-screen-lg mx-auto pt-10 mb-auto">
      <div class="mx-auto">
        <h1 className="text-4xl font-black text-center mb-2">New Product</h1>
        <p className="text-2xl text-center mb-14 text-gray-500 px-2">
          Start selling, imagination is the limit
        </p>
        <div class="grid grid-cols-1 lg:grid-cols-4 gap-6 mx-6">
          <Link
            to="/new/product"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto"
          >
            <img src="/img/cube.png" className="w-10 mr-2 h-10 my-auto" />
            <div>
              <h1 className="my-auto text-xl">Digital</h1>
              <h2 className="my-auto text-sm text-gray-500">
                Anything Digital
              </h2>
            </div>
          </Link>
          <Link
            to="/new"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto opacity-40"
          >
            <img src="/img/video.png" className="w-12 mr-2 h-12 my-auto" />
            <div>
              <h1 className="my-auto text-xl">Video</h1>
              <h2 className="my-auto text-sm text-gray-500">Sell Videos</h2>
            </div>
          </Link>
          <Link
            to="/new"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto opacity-40"
          >
            <img src="/img/photos.png" className="w-11 mr-2 h-11 my-auto" />
            <div>
              <h1 className="my-auto text-xl">Photo</h1>
              <h2 className="my-auto text-sm text-gray-500">Sell Photos</h2>
            </div>
          </Link>
          <Link
            to="/new"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto opacity-40"
          >
            <img src="/img/article.png" className="w-11 mr-2 h-11 my-auto" />
            <div>
              <h1 className="my-auto text-xl">Article</h1>
              <h2 className="my-auto text-sm text-gray-500">Sell Articles</h2>
            </div>
          </Link>
          <Link
            to="/new"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto opacity-40"
          >
            <img src="/img/audio.png" className="w-11 mr-2 h-11 my-auto" />
            <div className="my-auto">
              <h1 className="my-auto text-xl">Audio</h1>
              <h2 className="my-auto text-sm text-gray-500">Sell Audios</h2>
            </div>
          </Link>
          <Link
            to="/new"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto opacity-40"
          >
            <img src="/img/world.png" className="w-11 mr-2 h-11 my-auto" />
            <div className="my-auto">
              <h1 className="my-auto text-xl">Physical</h1>
              <h2 className="my-auto text-sm text-gray-500">
                Physical Products
              </h2>
            </div>
          </Link>
          <Link
            to="/new"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto opacity-40"
          >
            <img src="/img/tickets.png" className="w-11 mr-2 h-11 my-auto" />
            <div className="my-auto">
              <h1 className="my-auto text-xl">Tickets</h1>
              <h2 className="my-auto text-sm text-gray-500">Event tickets</h2>
            </div>
          </Link>
          <Link
            to="/new"
            class="flex justify-center p-6 bg-white shadow-md rounded-xl h-30 my-auto opacity-40"
          >
            <img src="/img/fund.png" className="w-11 mr-2 h-11 my-auto" />
            <div className="my-auto">
              <h1 className="my-auto text-xl">More...</h1>
              <h2 className="my-auto text-sm text-gray-500">Coming soon</h2>
            </div>
          </Link>
          {/* <a
            class="flex justify-center p-6 bg-base-200 border-2 border-base-300 rounded-xl"
          >
            <img src="/img/donation.png" className="w-11 mr-2 h-11 my-auto" />
            <div className="my-auto">
              <h1 className="my-auto text-xl">Coming soon</h1>
              <h2 className="my-auto text-sm text-gray-500">Stay tuned</h2>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
}
