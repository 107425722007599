import getweb3 from "./getweb3";
import toast from "react-hot-toast";
import CiripContract from "../contracts/Cirip.json";
import { getIPFSHead, getTxData } from "./api";
import configstore from "../stores/configstore";

class CiripService {
  constructor() {
    this.contract = null;
    this.contractAddress = null;
  }

  listen = async () => {
    // const ev = this.contract.events.newInboxMessage();
    // ev.on("data", (d) => console.log(d));
  };

  setContract = async () => {
    try {
      const networkId = await getweb3.web3.eth.net.getId();
      const deployedNetwork = CiripContract.networks[networkId];
      this.contractAddress = deployedNetwork.address;
      this.contract = new getweb3.web3.eth.Contract(
        CiripContract.abi,
        deployedNetwork && deployedNetwork.address
      ); //   this.listen();
    } catch (error) {
      console.log(error);
    }
  };

  checkProductReady = async (cid) => {
    try {
      const isReady = await getIPFSHead(cid)
      if (isReady) {
        return true;
      } else {
        toast.error("This product is not ready for sale yet. It will take a few minutes to propagate to IPFS.", {
          duration: 10000,
          style: {
            backgroundColor: 'crimson',
            color: 'white'
          }
        });
        return false;
      }
    } catch (error) {
      return false;
      console.log(error);
    }
  };


  buyCall = async (id, data) => {
    try {
      const txData = await getTxData(
        id,
        configstore.signature,
        JSON.stringify(data)
      );
      // console.log(txData);
      const gasPrice = await getweb3.web3.eth.getGasPrice();
      const gas = await this.contract.methods
        .buyProduct(txData.seller, txData.id, txData.signature, txData.mid)
        .estimateGas({ from: getweb3.accounts[0], value: txData.value });
      console.log(gasPrice);
      const r = await this.contract.methods
        .buyProduct(txData.seller, txData.id, txData.signature, txData.mid)
        .send({
          from: getweb3.accounts[0],
          value: txData.value,
          gasPrice: gasPrice,
          gas: gas,
        });
      return r;
    } catch (error) {
      throw error;
    }
  };
}

export default new CiripService();
