import { makeAutoObservable, action } from 'mobx'

class ConfigStore {
  user = null
  delegateToken = null
  contract = null;
  signature = null;
  web3 = false;
  showConnectModal = false;
  chainName = null;
  chainID = null;
  accounts = [];

  constructor() {
    makeAutoObservable(this);
  }

  setWeb3 = action(enable => {
    this.web3 = enable;
  })

  setShowConnectModal = action(enable => {
    this.showConnectModal = enable;
  })

  setChain = action((name, id) => {
    this.chainName = name;
    this.chainID = id;
  })

  setSignature = action(sign => {
    this.signature = sign;
  })

  setDelegateToken = action(token => {
    this.delegateToken = token;
  })

}
export default new ConfigStore();