import React from "react";
import ReactMarkdown from "react-markdown";

export default function Description({editorState, remarkGfm}) {
  return (
    <div>
      <div className="divider text-gray-400">Details</div>
      <div className="break-words markdown ">
        <ReactMarkdown children={editorState} remarkPlugins={[remarkGfm]} />
      </div>
    </div>
  );
}
